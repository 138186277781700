import React from  'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';


export default function BlogCard ({post, index}) {
    const { slug } = post.fields;
    const { title, date, featuredimage} = post.frontmatter;
    let postImage = featuredimage ? featuredimage.childImageSharp.fluid.src : null;

    return(
        <div className="blog-card-wrapper">
            <div className="blog-card-img"></div>
            <Card
                className='blog-card'>
                <Hidden xsDown>
                { postImage && postImage !== null ? 
                <CardMedia
                   className='image'
                   image={postImage}
                   height="140"
                   /> 
                   : ''
                   }
                </Hidden>
                <div className={postImage !== null ? 'content' : 'content-full'}>
                    <CardHeader
                        className="title"
                        title={title}
                        subheader={date}
                    />
                    <CardContent>
                        <Typography paragraph>
                            {post.excerpt}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <a href={slug} alt={post.title}>
                            <Button size="medium" color="primary" className='card-button'>
                                Läs hela nyheten
                                <Icon>arrow_right_alt</Icon>
                            </Button>
                        </a>
                    </CardActions>
                </div>
            </Card>
        </div>
    )
}