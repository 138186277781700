import React from 'react'
import BlogCard from './BlogCard'

export default class BlogList extends React.Component {
  render() {
    let posts = this.props.posts

    return (
      <div className="">
        {posts &&
          posts.map(({ node: post }, i) => (
            <BlogCard post={post} key={post.id} index={i}/>
          ))}
      </div>
    )
  }
}
